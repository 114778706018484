<template>
  <div class="home">
    <h2>4RK arkitektkontor</h2>
    <p>Epost: <a href="mailto:magnus.wahlgren@gmail.com">Kontoret</a></p>
    <p>Telefon: <a href="tel:+46709514993">+46 (0)70 951 49 93</a></p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Home",
});
</script>
